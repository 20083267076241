import React, { useState } from 'react';
import { Link } from 'gatsby';
import { Modal, Form, Input, Button, Row, Col, Switch, Alert } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

export default function TenantCreation(props) {
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async () => {
    try {
      const values = await form.validateFields();
      setLoading(true);
      // setShowError(false);

      // window.grecaptcha.ready(async function () {
      // const token = await window.grecaptcha.execute('6Lck_KgUAAAAAEc9wSTBGSUxz3enEfe3rF_LA7G4', { action: 'homepage' });

      const res = await fetch('https://app.petoffice.eu/new-api/tenant-creation/', {
        method: 'POST',
        cache: 'no-cache',

        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        redirect: 'manual',
        referrer: 'client',
        body: JSON.stringify({
          ...values,
          domain: values.organizationName,
          // recaptchaResponse: token,
        })
      });

      if (res.ok) {
        setLoading(false);
        form.resetFields();
        setShowSuccess(true);
      } else {
        setShowError(true);
        setLoading(false);
      }
      // });
    } catch (e) {
      console.error('error', e);
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        title="petoffice kostenlos testen"
        centered
        visible={props.visible}
        onCancel={props.onCancel}
        width={800}
        footer={showSuccess ? [
          <Button key="submit" type="primary" onClick={props.onCancel} loading={loading}>
            Schließen
          </Button>
        ] : [
          <Button key="back" onClick={props.onCancel} loading={loading}>
            Abbrechen
          </Button>,
          <Button key="submit" type="primary" onClick={onFinish} loading={loading}>
            Jetzt loslegen
          </Button>
        ]}
      >
        {showError && (
          <div style={{ marginBottom: '2rem' }}>
            <Alert message="Entschuldige, aber beim Anmelden ist ein Fehler aufgetreten. Bitte setze dich unter support@petoffice.eu mit uns in Verbindung." type="error" />
          </div>
        )}

        {!showSuccess && (
          <Form
            form={form}
            layout="vertical"
          >
            <Form.Item name="email" label="E-Mail-Adresse" required
              rules={[
                {
                  required: true,
                  message: 'Bitte gib deine E-Mail-Adresse an',
                },
              ]}>
              <Input placeholder="max.mustermann@example.com" />
            </Form.Item>
            <Form.Item name="organizationName" label="Name des Vereins" required rules={[
              {
                required: true,
                message: 'Bitte gib den Namen deines Vereins an',
              },
            ]}>
              <Input placeholder="Meister Petz e.V." />
            </Form.Item>
            <Form.Item label="Ansprechpartner &amp; Anschrift" required>
              <Row gutter={[8, 8]}>
                <Col span={12}>
                  <Form.Item name="givenName" required rules={[
                    {
                      required: true,
                      message: 'Bitte gib deinen Vornamen an',
                    },
                  ]}>
                    <Input placeholder="Max" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="familyName" required rules={[
                    {
                      required: true,
                      message: 'Bitte gib deinen Nachnamen an',
                    },
                  ]}>
                    <Input placeholder="Mustermann" />
                  </Form.Item>
                </Col>
                <Col span={18}>
                  <Form.Item name="street" required rules={[
                    {
                      required: true,
                      message: 'Bitte gib deinen Straßennamen an',
                    },
                  ]}>
                    <Input placeholder="Musterstraße" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="streetNo" required rules={[
                    {
                      required: true,
                      message: 'Hausnummer fehlt',
                    },
                  ]}>
                    <Input placeholder="2" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="zip" required rules={[
                    {
                      required: true,
                      message: 'Bitte gib deine PLZ an',
                    },
                  ]}>
                    <Input placeholder="12345" />
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item name="city" required rules={[
                    {
                      required: true,
                      message: 'Bitte gib deinen Wohnort an',
                    },
                  ]}>
                    <Input placeholder="Musterhausen" />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item valuePropName="checked" name="toc" required label={<span>Ja, ich habe die <a href="/agb/" target="_blank">Allgemeinen Geschäftsbedingungen</a> von petoffice gelesen und akzeptiere diese.</span>} rules={[
              {
                required: true,
                message: 'Du musst die AGB akzeptieren',
                len: 4,
                transform: (val) => val ? "true" : "false",
              },
            ]}>
              <Switch />
            </Form.Item>
            <Form.Item name="privacy" label={<span>Ja, ich habe die <a href="/datenschutz/" target="_blank">Datenschutzerklärung</a> von petoffice gelesen und akzeptiere, dass meine eingegebenen Daten zur Auftragsbearbeitung verwendet werden. Die detaillierte Beschreibung in der Datenschutzerklärung habe ich zur Kenntnis genommen.</span>} valuePropName="checked" required rules={[
              {
                required: true,
                message: 'Du musst die Datenschutzerklärung akzeptieren',
                len: 4,
                transform: (val) => val ? "true" : "false",
              },
            ]}>
              <Switch />
            </Form.Item>
            <small>Bitte melde dich mit deinen realen Kontaktdaten an, sodass wir echte Registrierungen von Spam unterscheiden können. Anmeldung mit Wegwerf-Mailadressen oder falschen Kontaktdaten werden ohne Rückfrage entfernt.</small>
          </Form>
        )}

        {showSuccess && (
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '2rem' }}>
            <CheckCircleOutlined style={{ fontSize: '8rem' }} />
            <p style={{ textAlign: 'center' }}>Herzlichen Glückwunsch. Dein petoffice wird in diesem Moment erstellt. Du bekommst in Kürze eine E-Mail mit den Zugangsdaten. Sollte diese nicht ankommen, schaue bitte zusätzlich im Spam-Ordner deines Postfachs nach.</p>
            <p style={{ textAlign: 'center' }}>Vielen Dank für dein Vertrauen und viel Spaß!</p>
          </div>
        )}
      </Modal>
    </>
  );
};
